import React from 'react';
import { Link } from 'gatsby';
import { IoLogoLinkedin, IoLogoInstagram } from "react-icons/io";
import Logo_Joutsen from '../../assets/images/EditaPrima_Joutsen_Painotuotteet_nega2.png';
import Logo_MSC from '../../assets/images/msc.svg';
import { injectIntl } from "gatsby-plugin-react-intl";
import NewsletterSubscription from "../newsletter/NewsletterSubscription";

const Footer = ({ links, newsletterFormTexts, intl, nodeLocale }) => {
  const locales = {
    en: "en-US",
    fi: "fi-FI",
  }

  const node = links.filter(item => item.node_locale === locales[intl.locale]);
  let { navigationLinks } = node[0];

  let locale = nodeLocale === 'en-US' ? 'en' : 'fi';

  return (
    <footer className="bg-blackcurrant px-4 sm:px-6 md:px-12 lg:px-20 py-12 lg:py-24">
      <div className="max-w-screen-xl mx-auto flex md:flex-wrap md-max:flex-col justify-between">
        <div className="flex flex-col md:w-6/12 lg:w-4/12 lg:order-1">
          <div className="flex xxl-max:flex-col md-max:text-center">
            <nav className="footer-navigation mb-8 md:pr-3">
              <ul>
                {navigationLinks?.map((link) => {
                  // Check if link is external or internal
                  let linkItem;

                  if (link?.linkUrl) {
                    linkItem = <a className="inline-block py-4 text-white" href={link.linkUrl} target="_blank" rel="noreferrer">{link.linkName}</a>
                  } else {
                    linkItem = <Link className="inline-block py-4 text-white" to={`/${locale}/${link.linkToEntry.slug}`}>{link.linkName}</Link>
                  }

                  return (
                    <li>
                      {linkItem}
                    </li>
                  )
                })}
                <li><a className="inline-block py-4 text-white" href="#" onClick={() => window && window.UC_UI.showSecondLayer()}>{locale === 'en' ? 'Privacy settings' : 'Yksityisyysasetukset'}</a></li>
              </ul>
            </nav>
          </div>
          <div className="mt-auto w-full lg-max:hidden">
            <p>&copy; {new Date().getFullYear()} <Link to={`/${locale}`}>Edita Prima</Link></p>
          </div>
        </div>

        <div className="newsletter-form md:w-6/12 lg:w-4/12 lg:order-3 md:flex md:flex-col">
          <NewsletterSubscription newsletterFormTexts={newsletterFormTexts} nodeLocale={nodeLocale} />
          <ul className="lg-max:mb-12 lg-max:mt-12 lg:mt-auto text-white md-max:text-center text-4xl lg:text-5xl flex justify-center md:justify-end">
            <li className="px-3.5">
              <img width="54px" height="65px" src={Logo_Joutsen} alt="" />
            </li>
            <li className="px-3.5">
              <img width="54px" height="54px" src={Logo_MSC} alt="" />
            </li>
            <li className="px-3.5">
              <Link
                to="https://www.linkedin.com/company/edita-prima-oy"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">LinkedIn</span>
                <IoLogoLinkedin size={54} />
              </Link>
            </li>
            <li className="px-3.5">
              <Link
                to="https://www.instagram.com/editaprimaoy/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">Instagram</span>
                <IoLogoInstagram size={54} />
              </Link>
            </li>
          </ul>
        </div>

        <div className="md:w-full lg:w-3/12 lg:w-auto flex flex-col justify-center text-center lg:order-2">
          <p className="logo-text uppercase text-brightTurquoiseApprox">{newsletterFormTexts?.bottomTextLine1}<br />{newsletterFormTexts?.bottomTextLine2}</p>
        </div>

        <div className="mt-12 w-full text-center lg:hidden">
          <p>&copy; {new Date().getFullYear()} <Link to={`/${locale}`}>Edita Prima</Link></p>
        </div>
      </div>
    </footer >
  )

}

export default injectIntl(Footer);